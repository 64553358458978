import * as Styled from './OxAnimatedWrapper.styled';

import React, { PropsWithChildren } from 'react';

export enum EAnimatedWrapperAnimation {
    FadeIn = 'FADE_IN',
    SlideInHorizontal = 'SLIDE_IN_HORIZONTAL',
    SlideInVertical = 'SLIDE_IN_VERTICAL',
    Stack = 'STACK'
}

type TProps = {
    animation: EAnimatedWrapperAnimation;
    active: boolean;
    relativeBox?: boolean;
    isNext?: boolean;
    noAbsoluteOnInactive?: boolean;
};

export const OxAnimatedWrapper = ({
    children,
    ...props
}: PropsWithChildren<TProps>): JSX.Element => {
    return <Styled.Container {...props}>{children}</Styled.Container>;
};
