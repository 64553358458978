import styled, { css } from 'styled-components';

import { EAnimatedWrapperAnimation } from './OxAnimatedWrapper';

export const Container = styled.div<{
    animation: EAnimatedWrapperAnimation;
    active: boolean;
    relativeBox?: boolean;
    isNext?: boolean;
    noAbsoluteOnInactive?: boolean;
}>(({ active, animation, isNext, relativeBox, noAbsoluteOnInactive }) => {
    let animationCss;
    const absoluteSlideInCss = css`
        opacity: 0;
        visibility: hidden;
        transition-duration: 800ms;
        transition-property: transform, opacity;
        transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
        ${active &&
        css`
            transition-duration: 800ms, 2000ms;
            transition-property: transform, opacity;
            transform: translate(0, 0);
            opacity: 1;
            visibility: visible;
        `}
    `;
    switch (animation) {
        case EAnimatedWrapperAnimation.FadeIn:
            animationCss = css`
                opacity: 0;
                visibility: hidden;
                transition-duration: 400ms;
                ${active &&
                css`
                    opacity: 1;
                    visibility: visible;
                    z-index: 1;
                `}
            `;
            break;
        case EAnimatedWrapperAnimation.SlideInHorizontal:
            animationCss = css`
                transform: translateX(100%);
                ${absoluteSlideInCss}
                ${!isNext &&
                !active &&
                css`
                    transform: translateX(-100%);
                `}
            `;
            break;
        case EAnimatedWrapperAnimation.SlideInVertical:
            animationCss = css`
                transform: translateY(100%);
                ${absoluteSlideInCss}
                ${!isNext &&
                !active &&
                css`
                    transform: translateY(-100%);
                `}
            `;
            break;
        case EAnimatedWrapperAnimation.Stack:
            animationCss = css`
                position: absolute;
                opacity: 0;
                visibility: hidden;
                transition-duration: 400ms;
                ${active &&
                css`
                    position: static;
                    opacity: 1;
                    visibility: visible;
                    z-index: 1;
                `}
            `;
            break;
    }

    const absoluteCss = css`
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    `;

    return css`
        ${!relativeBox && absoluteCss}
        ${relativeBox && !active && !noAbsoluteOnInactive && absoluteCss}
    ${animationCss}
    `;
});
